<template>
<div>

</div>
</template>
<script>
export default {
  name: "ResetMetodeVerifikasiView",
  components: {},
  data() {
    return {
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
  },


};

</script>

<style scoped>

</style>